import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import PublicLayout from './layouts/Public';
import DashboardLayout from './layouts/Dashboard';
import AssetsDashboard from './layouts/AssetsDashboard/AssetsDashboard';
import FilesDashboard from './layouts/Files/FilesDashboard';
import { useUiVersion } from './modules/ui/UserInterfaceVersionProvider';
import MyDiskView from './views/MyDisk';
import Report from './views/Dashboard/Report';
import SearchView from './views/MyDisk/Search';
import { AuthGuard, GuestGuard } from './components';
import route from './utils/routes';
import { applyFilters, Filters } from './core';
import { RouteConfig } from 'utils/render-routes';
import appConfig from 'config/app';

const ContextualLayout = ({ ...props }: any) => {
  const { version } = useUiVersion();
  const Dashboard = version === 'v2' ? AssetsDashboard : DashboardLayout;

  return <Dashboard {...props} />;
};

const ContextualUsersLayout = ({ ...props }: any) => {
  const { version } = useUiVersion();
  const Dashboard =
    version === 'v2'
      ? appConfig.enableAssetsModule
        ? AssetsDashboard
        : FilesDashboard
      : DashboardLayout;

  return <Dashboard {...props} />;
};

const ComponentsView = lazy(() => import('./views/Components/Components'));

export const generateRoutes = (
  config: any,
  extraRoutes = [],
  extraPublicRoutes = [],
): RouteConfig[] => [
  {
    path: route('/auth/*'),
    component: (props: any) => (
      <GuestGuard>
        <AuthLayout {...props} />
      </GuestGuard>
    ),
    routes: [
      {
        path: 'login',
        exact: true,
        component: lazy(() => import('./views/LoginModern/Login')),
      },
      {
        path: 'password-reset',
        exact: true,
        component: lazy(
          () => import('./views/PasswordResetModern/PasswordReset'),
        ),
      },
      {
        path: 'password-reset/:token',
        component: lazy(
          () => import('./views/PasswordResetModern/PasswordReset'),
        ),
      },
      {
        component: () => <Navigate to="/errors/404" />,
      },
    ],
  },
  {
    path: route('/errors/*'),
    component: ErrorLayout,
    routes: [
      {
        path: route('401'),
        exact: true,
        component: lazy(() => import('./views/Error/401')),
      },
      {
        path: route('404'),
        exact: true,
        component: lazy(() => import('./views/Error/404')),
      },
      {
        path: route('500'),
        exact: true,
        component: lazy(() => import('./views/Error/500')),
      },
      {
        component: () => <Navigate to="/errors/404" />,
      },
    ],
  },
  {
    path: route('/public/*'),
    component: (props: any) => <PublicLayout {...props} />,
    routes: extraPublicRoutes,
  },
  {
    path: '/assets/*',
    component: (props: any) => (
      <AuthGuard>
        <AssetsDashboard {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: '/report/:id/view/:item',
        component: lazy(() => import('./views/Dashboard/Details')),
      },
      {
        path: '/report/view/:item',
        component: lazy(() => import('./views/Dashboard/Details')),
      },
      {
        path: '/report/:id',
        component: Report,
      },
      {
        path: '/search',
        exact: true,
        component: lazy(() => import('./views/Dashboard/Search')),
      },
      {
        path: '/types/:id',
        exact: true,
        component: lazy(() => import('./views/Assets/AssetsTypeDetails')),
      },
      {
        path: '/types',
        exact: true,
        component: lazy(() => import('./views/Assets/AssetsTypes')),
      },
      {
        path: '/groups',
        exact: true,
        component: lazy(() => import('./views/Assets/AssetsGroups')),
      },
      {
        path: '*',
        exact: true,
        component: lazy(() => import('./views/Dashboard/Index')),
      },
    ],
  },
  {
    path: '/users/*',
    component: (props: any) => (
      <AuthGuard>
        <ContextualUsersLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(
          () => import(/* webpackChunkName: 'users-legacy' */ './views/Users'),
        ),
      },
      {
        path: '/:tab/:id',
        component: lazy(
          () => import(/* webpackChunkName: 'users-legacy' */ './views/Users'),
        ),
      },
      {
        path: '/:tab',
        component: lazy(
          () => import(/* webpackChunkName: 'users-legacy' */ './views/Users'),
        ),
      },
      {
        path: '/:tab/search/:query',
        component: lazy(
          () => import(/* webpackChunkName: 'users-legacy' */ './views/Users'),
        ),
      },
    ],
  },
  {
    path: '/v2/users/*',
    component: (props: any) => (
      <AuthGuard>
        <ContextualUsersLayout
          {...props}
          dashboardProps={{
            shouldResetContextBarStateOnPageChange: false,
          }}
        />
      </AuthGuard>
    ),
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(
          () =>
            import(/* webpackChunkName: 'users' */ 'views/UsersModern/Index'),
        ),
      },
      {
        path: '/:tab/view/:id',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'users' */ 'views/UsersModern/Index'),
        ),
      },
      {
        path: '/:tab/edit/:id',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'users' */ 'views/UsersModern/Index'),
        ),
      },
      {
        path: '/:tab/:id',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'users' */ 'views/UsersModern/Index'),
        ),
      },
      {
        path: '/:tab',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'users' */ 'views/UsersModern/Index'),
        ),
      },
      {
        path: '/:tab/search/:query',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'users' */ 'views/UsersModern/Index'),
        ),
      },
    ],
  },
  {
    path: '/components/*',
    component: (props: any) => (
      <AuthGuard>
        <ContextualLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: '/',
        exact: true,
        component: ComponentsView,
      },
      {
        path: '/:tab/view/:id',
        component: ComponentsView,
      },
      {
        path: '/:tab/edit/:id',
        component: ComponentsView,
      },
      {
        path: '/:tab/search/:query',
        component: ComponentsView,
      },
      {
        path: '/:tab/:id',
        component: ComponentsView,
      },
      {
        path: '/:tab',
        component: ComponentsView,
      },
    ],
  },
  {
    path: '/user/*',
    component: (props: any) => (
      <AuthGuard>
        <ContextualUsersLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: '/:id',
        component: lazy(() => import('./views/UserDetails')),
      },
    ],
  },
  {
    path: '/notifications/*',
    component: (props: any) => (
      <AuthGuard>
        <ContextualLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('./views/Feedback')),
      },
    ],
  },
  {
    path: '/service-tickets/*',
    component: (props: any) => (
      <AuthGuard>
        <ContextualLayout
          {...props}
          dashboardProps={{
            shouldResetContextBarStateOnPageChange: false,
          }}
        />
      </AuthGuard>
    ),
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('./views/ServiceTickets/ServiceTickets')),
      },
      {
        path: '/:id',
        component: lazy(() => import('./views/ServiceTickets/ServiceTickets')),
      },
    ],
  },
  {
    path: '/settings/*',
    component: (props: any) => (
      <AuthGuard>
        <ContextualLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('./views/Settings')),
      },
      {
        path: '/:tab/:extra',
        component: lazy(() => import('./views/Settings')),
      },
      {
        path: '/:tab',
        component: lazy(() => import('./views/Settings')),
      },
    ],
  },
  {
    path: '/v2/*',
    component: (props: any) => (
      <AuthGuard>
        <FilesDashboard {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: route(''),
        exact: true,
        component: lazy(() => import('./views/Files/Files')),
      },
      {
        path: route('/search/:query'),
        exact: true,
        component: lazy(() => import('./views/Files/Search')),
      },
      {
        path: route('/directory/:id/search/:query'),
        exact: true,
        component: lazy(() => import('./views/Files/Files')),
      },
      {
        path: route('/directory/:id'),
        exact: true,
        component: lazy(() => import('./views/Files/Files')),
      },
    ],
  },
  {
    path: '/logs/*',
    component: (props: any) => (
      <AuthGuard>
        <ContextualLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: '',
        exact: true,
        component: lazy(() => import('./views/Logs/Logs')),
      },
    ],
  },
  {
    path: '/*',
    component: (props: any) => (
      <AuthGuard>
        <DashboardLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: route(''),
        exact: true,
        component: applyFilters(Filters.app.rootComponent, MyDiskView),
      },
      {
        path: route(appConfig.myDiskPath),
        exact: true,
        component: MyDiskView,
      },
      {
        path: route('/search/:search'),
        exact: true,
        component: SearchView,
      },
      {
        path: route('/directory/:id'),
        exact: true,
        component: MyDiskView,
      },
      {
        path: route('/report/:id/view/:item'),
        component: lazy(() => import('./views/Reports/Details')),
      },
      {
        path: route('/report/:id/type/:type'),
        component: lazy(() => import('./views/Reports/Next')),
      },
      {
        path: route('/report/:id/:state'),
        component: lazy(() => import('./views/Reports/Next')),
      },
      {
        path: route('/report/:id'),
        component: lazy(() => import('./views/Reports/Next')),
      },
      {
        path: route('/report'),
        component: lazy(() => import('./views/Reports/Next/Root')),
      },
      ...extraRoutes,
      {
        component: () => <Navigate to="/errors/404" />,
      },
    ],
  },
];
