import React from 'react';
import clsx from 'clsx';
import css from './drop-overlay.module.scss';
import Typography from '../Typography/Typography';
import IconDefault from '../Icon/Icon';
import Spacer from '../Spacer/Spacer';

type Props = {
  icon?: React.ComponentType<{ className: string }>;
};

const DropOverlay = ({
  children,
  icon: Icon,
}: React.PropsWithChildren<Props>) => (
  <div className={clsx(css.root)}>
    <div className={css.content}>
      {Icon ? (
        <Icon className={css.icon} />
      ) : (
        <IconDefault
          name="fa-sharp fa-regular fa-cloud-arrow-up"
          className={css.icon}
        />
      )}
      <Spacer />
      <Typography color="inherit">
        {children || 'Drop here files to upload'}
      </Typography>
    </div>
  </div>
);

export default DropOverlay;
