import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme';
import clsx from 'clsx';
import { colors, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1500,
    backgroundColor: colors.blueGrey[900],
    opacity: 0.7,
    justifyContent: 'center',
    justifyItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    justifyItems: 'center',
    textAlign: 'center',
    // @ts-ignore
    color: theme.palette.white,
  },
  icon: {
    fontSize: theme.spacing(6),
    width: theme.spacing(6),
    height: theme.spacing(6),
    margin: '0 auto',
  },
}));

export const DropHere: React.FC<any> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <AddCircleOutlineIcon className={classes.icon} />
        <Typography color="inherit">Drop here files to upload</Typography>
      </div>
    </div>
  );
};
